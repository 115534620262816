:root {
    --Gray-0: #f8f9fa;
    --Gray-1: #f1f3f5;
    --Gray-2: #e9ecef;
    --Gray-3: #dee2e6;
    --Gray-4: #ced4da;
    --Gray-5: #adb5bd;
    --Gray-6: #868e96;
    --Gray-7: #495057;
    --Gray-8: #343a40;
    --Gray-9: #212529;
    --Gray-10: rgba(215, 219, 223, 0);
    /* /////////////////////////////////////////////////////////// */
    --Red-0: #fff5f5;
    --Red-1: #ffe3e3;
    --Red-2: #ffc9c9;
    --Red-3: #ffa8a8;
    --Red-4: #ff8787;
    --Red-5: #ff6b6b;
    --Red-6: #fa5252;
    --Red-7: #f03e3e;
    --Red-8: #e03131;
    --Red-9: #c92a2a;
    /* /////////////////////////////////////////////////////////// */
    --Violet-0: #f3f0ff;
    --Violet-1: #e5dbff;
    --Violet-2: #d0bfff;
    --Violet-3: #b197fc;
    --Violet-4: #9775fa;
    --Violet-5: #845ef7;
    --Violet-6: #7950f2;
    --Violet-7: #7048e8;
    --Violet-8: #6741d9;
    --Violet-9: #5f3dc4;
    /* /////////////////////////////////////////////////////////// */
    --Indigo-0: #edf2ff;
    --Indigo-1: #dbe4ff;
    --Indigo-2: #bac8ff;
    --Indigo-3: #91a7ff;
    --Indigo-4: #748ffc;
    --Indigo-5: #5c7cfa;
    --Indigo-6: #4c6ef5;
    --Indigo-7: #4263eb;
    --Indigo-8: #3b5bdb;
    --Indigo-9: #2c42ad;
    /* /////////////////////////////////////////////////////////// */
    --Blue-0: #e7f5ff;
    --Blue-1: #d0ebff;
    --Blue-2: #a5d8ff;
    --Blue-3: #74c0fc;
    --Blue-4: #4dabf7;
    --Blue-5: #339af0;
    --Blue-6: #228be6;
    --Blue-7: #1c7ed6;
    --Blue-8: #1971c2;
    --Blue-9: #1864ab; /*Header Box shadow*/
    --Blue-10: #034685;
    --Blue-11: rgba(65, 93, 129, 0.897);
    --Blue-12: #012753;
    /* /////////////////////////////////////////////////////////// */
    --Green-0: #ebfbee;
    --Green-1: #d3f9d8;
    --Green-2: #b2f2bb;
    --Green-3: #8ce99a;
    --Green-4: #69db7c;
    --Green-5: #51cf66;
    --Green-6: #40c057;
    --Green-7: #37b24d;
    --Green-8: #2f9e44;
    --Green-9: #2b8a3e;
    --Green-10: #262e24;
    /* /////////////////////////////////////////////////////////// */
    --Lime-0: #f4fce3;
    --Lime-1: #e9fac8;
    --Lime-2: #d8f5a2;
    --Lime-3: #c0eb75;
    --Lime-4: #a9e34b;
    --Lime-5: #94d82d;
    --Lime-6: #82c91e;
    --Lime-7: #74b816;
    --Lime-8: #66a80f;
    --Lime-9: #5c940d;
    /* /////////////////////////////////////////////////////////// */
    --Yellow-0: #fff9db;
    --Yellow-1: #fff3bf;
    --Yellow-2: #ffec99;
    --Yellow-3: #ffe066;
    --Yellow-4: #ffd43b;
    --Yellow-5: #fcc419;
    --Yellow-6: #fab005;
    --Yellow-7: #f59f00;
    --Yellow-8: #f08c00;
    --Yellow-9: #e67700;
    /* /////////////////////////////////////////////////////////// */
    --Orange-0: #fff4e6;
    --Orange-1: #ffe8cc;
    --Orange-2: #ffd8a8;
    --Orange-3: #ffc078;
    --Orange-4: #ffa94d;
    --Orange-5: #ff922b;
    --Orange-6: #fd7e14;
    --Orange-7: #f76707;
    --Orange-8: #e8590c;
    --Orange-9: #d9480f;
    --Orange-10: #f87617;
}

*:focus {
    outline: none;
    outline-width: 0;
}

* {
    box-sizing: border-box;
    margin: 0px;
    font-family: Arial, sans-serif;
}

/* BASIC SETUP */
.Toastify {
    position: absolute;
}

.Toastify__toast-container {
    margin-top: 6rem;
    z-index: 110;
}

#root {
    display: grid;
    grid-template-areas:
        "header" "body" "footer";
    grid-template-rows: minmax(7rem, min-content) minmax(min-content, 1fr) minmax(min-content, 10rem);
    background-color: var(--Gray-0);
    min-height: 100vh;
    padding: 0;
    background: linear-gradient(-20deg, rgba(212, 187, 141, 0.726), var(--Gray-0)), url(/src/images/backgrounds/04.jpg);
    background-size: cover;
    font-family: 'Inter', sans-serif;
}


.typ-item-btnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.typ-item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    color: var(--Gray-1);
    border: 0;
    border-radius: 5px;
    margin: 0 0.4rem;
    padding: 8px 16px;
    padding-top: 9px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.typ-item-btn:hover {
    box-shadow: 0 0 4px var(--Gray-9);
    transition: all 0.3s ease-in-out;
}

.btnPrimary {
    background-color: var(--Blue-8);
    color: var(--Gray-0);
    margin: 0;
}

.btnSecondary {
    background-color: var(--Gray-6);
    color: var(--Gray-0);
    margin: 0;
}

.btnSuccess {
    background-color: var(--Green-7);
    color: var(--Gray-0);
    margin: 0;
}

.btnDanger {
    background-color: var(--Red-8);
    margin: 0;
    color: var(--Gray-0);
}

.btnDangerB {
    background-color: var(--Red-5);
    margin: 0;
    color: var(--Gray-0);
}

.btnWarning {
    width: 100%;
    background-color: var(--Orange-3);
    color: var(--Gray-9);
}

.btnInfo {
    /* background-color: var(--Indigo-2); */
    background-color: salmon;
    color: var(--Gray-0);
    box-shadow: 0 0 4px var(--Gray-4);
}