.login-page {
    grid-area: body;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: var(--Gray-8);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.register-page {
    grid-area: body;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: var(--Gray-8);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


/* .register-page-info{
    color: var(--Gray-2);
}

.register-page-info-icon{
    display: flex;
    gap: 1rem;
}

.bi-cash-coin {
    font-size: 3rem;
} */

.container {
    height: max-content;
    width: 24rem;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 0.6rem;
    background-color: var(--Gray-0);
}

.register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
}

.closeX {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--Gray-0);
    background-color: var(--Red-9);
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.closeX:hover {
    box-shadow: 0 0 5px var(--Red-6);
    cursor: pointer;
    color: var(--Gray-0);
}

.input-typ {
    display: flex;
    height: 2.5rem;
    background-color: var(--Gray-1);
    border: 1px solid var(--Gray-4);
    border-radius: 5px;
    padding: 0 0.5rem;
    width: 100%;
    font-size: 14px;
}

.input-typ:hover {
    background-color: var(--Gray-1);
    border: 1px solid var(--Blue-4);
}



.list-title{
    color: var(--Gray-7);
}