
/* ----------------------------------Footer------------------------------------------ */
.footer {
  grid-area: footer;
  position: relative;
  display: flex;
  margin-top: auto;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px var(--Blue-3);
  background-color: var(--Gray-8);
  /* background-color: var(--Green-10); */
  letter-spacing: 1.5px;
  color: var(--Gray-3);
  transition: all 0.3s ease-in-out;
}


.info {
  display: flex;
  gap: 0.5rem;
  line-height: 1.5rem;
  padding: 1rem 8vw;
  margin: auto;
  width: 100%;
}

.info img {
  width: 128px;
}


.footer-address {
  display: flex;
  flex-direction: column;
  color: var(--Gray-2);
}

.footer-address h2 {
  font-style: italic;
  font-family: 'Noto Serif Old Uyghur', serif;
  color: var(--Blue-3);
}



.footer-ezcode{
  display: flex;
  flex-direction: column;
  color: var(--Gray-5);
  align-self: flex-end;
  margin-left: auto;
}


.info a {
  display: inline-block;
  text-decoration: underline;
  color: inherit;
  transition: all 0.2s ease-in-out;
}

.info a:hover {
  color: yellow;
  transition: all 0.2s ease-in-out;
}

/* ------------------------------------------------------------------------ */
@media (max-width: 1020px) {
  .info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer-ezcode {
    display: flex;
    flex-direction: column;
    color: var(--Gray-5);
    margin: auto;
  }
}

/* ------------------------------------------------------------------------ */
@media (max-width: 750px) {
  .footer {
    padding: 10px;
    padding-bottom: 1rem;
    transition: all 0.3s ease-in-out;
  }
}

/* ------------------------------------------------------------------------ */
@media (max-width: 580px) {
  .footer {
    grid-template-columns: 100%;
    grid-template-areas: "ft1";
    transition: all 0.3s ease-in-out;
    padding: 0;
  }

  .footer-address h2 {
    font-size: 1.1rem;
  }

  .info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .info img {
    width: 100px;
  }

  .footer-ezcode {
    display: flex;
    flex-direction: column;
    color: var(--Gray-5);
    margin: auto;
  }
}


/* ------------------------------------------------------------------------ */
@media (max-width: 385px) {
  .footer {
    grid-template-columns: min-content;
    align-items: center;
    justify-content: center;
    grid-template-areas: "ft1";
  }

  .footer-address h2 {
    font-size: 1rem;
  }

  .info img {
    width: 80px;
  }
}
