
.dashboard{
    grid-area: body;
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-template-rows: 1fr;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    background-color: var(--Gray-3);
    border-right: 1px solid var(--Gray-5);
    height: 100%;
    box-shadow: 0 11px 10px var(--Gray-7);
}

.d-menu div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.d-menu div:nth-child(3) {
    margin-bottom: auto;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
}

.d-menu-avatar{
    width: 100px;
    height: 100px;
    margin-bottom: .5rem;
    border-radius: 50px;
    background-color: var(--Gray-8);
}

.d-menu-btn{
    align-self: flex-start;
    text-decoration: none;
    width: 100%;
    margin-bottom: 0.2rem;
    padding: 8px 0;
    text-align: center;
    color: var(--Gray-2);
    background-color: var(--Blue-11);
}

.d-menu-btn:hover {
    background-color: var(--Blue-8);
    cursor: pointer;
}

.d-menu-activeBtn {
    align-self: flex-start;
    text-decoration: none;
    width: 100%;
    margin-bottom: 0.2rem;
    padding: 8px 0;
    text-align: center;
    color: var(--Gray-0);
    background-color: var(--Blue-8);
}

/* ------------ overview page ------------ */
.d-overview{
    display: grid;
    grid-template-areas:
    'dPriceAdd dPriceList dPriceList dPriceList'
    'body body body body'
    'footer footer footer footer';
    grid-template-rows: min-content 3fr;
    padding: 1vw;
}

.price-add-form{
    grid-area: dPriceAdd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%-1rem;
    max-height: 15.5rem;
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: var(--Gray-0);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
}

.price-add-form h3,
.d-profile h3{
    color: var(--Blue-7);
    margin-bottom: 0.5rem;
    font-style: italic;
}

.card-btn-box {
    margin-left: auto;
}

.admin-price {
    grid-area: dPriceList;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin: 0.5rem;
    padding: 0.9rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
}

.admin-price-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--Blue-11);
    color: var(--Gray-3);
    box-shadow: 0 0 5px var(--Gray-5);
}

.admin-price-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: .5rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    background-color: var(--Gray-3);
    box-shadow: 0 0 5px var(--Gray-5);
}


.admin-price-title p,
.price-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    width: calc(100% / 5);
    text-align: center;
}

.price-col-in {
    display: flex;
    background-color: var(--Gray-1);
    border: 1px solid var(--Gray-4);
    border-radius: 5px;
    flex: 1 1 0;
    width: 100%;
    height: 1.5rem;
    margin: 0 5px;
    text-align: center;
}

.input-typ:hover,
.price-col-in:hover {
    background-color: var(--Gray-1);
    border: 1px solid var(--Blue-4);
}


/* ----------------- user page ----------------- */
.d-user {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-template-rows: min-content 3fr;
    padding: 1vw;
}

.d-profile{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%-1rem;
    max-width: 24rem;
    margin: 0.5rem;
    padding: 1rem;
    background-color: var(--Gray-0);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
}

.input-typ-box {
    position: relative;
    width: 100%;
}

.input-typ {
    display: flex;
    height: 2.5rem;
    background-color: var(--Gray-0);
    border: 1px solid var(--Gray-4);
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    width: 100%;
    font-size: 14px;
}

.input-typ:hover{
    background-color: var(--Gray-1);
    border: 1px solid var(--Blue-5);
}

.lbl-input-typ{
    position: absolute;
    top: -8px;
    left: 10px;
    font-size: 14px;
    color: var(--Gray-6);
    background-color: var(--Gray-0);
    border-radius:5px;
}

.profile-image {
    border: 1px solid var(--Gray-4);
    border-radius: 5px;
    margin: 0.5rem;
    padding: 0;
    width: 100%;
    height: 200px;
}



/* ----------------- receiver page ----------------- */
.d-receiver {
    display: grid;
    gap: 1rem;
    grid-template-areas:
        'receiver-info transfer receivers'
        'requests requests requests'
        'footer footer footer';
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: min-content 3fr;
    padding: 1vw;
}

.receiver-form{
    grid-area: receiver-info;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    height:fit-content;
    margin: 0.5rem;
    padding: 1rem;
    background-color: var(--Gray-0);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
}

.receiver-info {
    display: flex;
    gap: 1rem;
    width: 100%;
    margin-top: 0.5rem;
}

.d-receiver-list-container{
    grid-area: receivers;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: var(--Gray-0);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
}

.d-receiver-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
}

.d-receiver-list-row{
    display: grid;
    grid-template-columns: 1fr 6.5rem 1fr 6rem 7rem;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    padding: 4px;
    width: 100%;
    border: 1px solid var(--Gray-5);
    border-radius: 0.5rem;
    background-color: var(--Gray-2);
}


/* ----------------- transfer to canada form ----------------- */
.transfer-form {
    grid-area: transfer;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 42rem;
    height: fit-content;
    margin: 0.5rem;
    padding: 1rem;
    background-color: var(--Gray-0);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
}

.text-box-typ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    padding: 0 1rem;
    border-top: 1px solid var(--Gray-5);
    height: 2.5rem;
}

.transfer-requests{
    grid-area: requests;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: var(--Gray-0);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
}

.requests-row{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    border-bottom: 1px solid var(--Gray-7)
}


.requests-row:nth-child(even) {
    background-color: var(--Gray-1);
}


.requests-row:nth-child(odd){
    background-color: var(--Gray-3);
}

.requests-typ-box{
    position: relative;
    width: 100%;
    line-height: 1.5;
    border-radius: 7px;
    margin-top: 1.5rem;
    padding: 0.5rem;
}

.transfer-status {
    cursor: pointer;
    width:fit-content;
    padding: 0.2rem 0.4rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in;
}

.transfer-status:hover {
    cursor: pointer;
    background-color: var(--Yellow-0);
    box-shadow: 0 0 4px Black;
    transition: all 0.2s ease-in-out;
}



@media (max-width: 1700px) {
    .d-receiver-list-row {
        grid-template-columns: repeat(auto-fit, minmax(14.8rem, 1fr));
        grid-template-rows: repeat(5rem);
    }

    .d-receiver-list {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }
}

@media (max-width: 1500px) {
    .receiver-info{
        flex-direction: column;
    }
}

@media (max-width: 1270px) {
    .dashboard {
        grid-template-columns: 1fr;
        grid-template-rows: fit-content 1fr;
    }

    .d-menu {
        box-shadow: 0 2px 10px var(--Blue-9);
        padding-top:0.4rem ;
    }

    .d-menu div:nth-child(2) {
        align-content: start;
        padding: 0.25rem;
        gap: 0.5rem;
    }
}

@media (max-width: 1100px) {
    .admin-price-title p:nth-child(2),
    .admin-price-row > p:nth-child(2) {
        display: none;
    }
}

@media (max-width: 1000px) {
    .d-receiver {
        grid-template-areas:
            'receiver-info'
            'transfer'
            'receivers'
            'requests'
            'footer';
        grid-template-columns:1fr;
    }

    .receiver-info {
        flex-direction: row;
    }

    .transfer-form {
        width: 100%;
    }
}

@media (max-width: 780px) {
    .admin-price-title p:nth-child(5),
    .admin-price-row .price-col:nth-child(5) {
        display: none;
    }

    .d-overview {
        grid-template-areas:
            'dPriceAdd'
            'dPriceList'
            'body'
            'footer';
    }

    .receiverForm {
        grid-area: receiver-info;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        max-width: 21rem;
        margin: 0.5rem;
        padding: 1rem;
        background-color: var(--Gray-0);
        border-radius: 0.5rem;
        box-shadow: 0 0 10px var(--Gray-7);
    }
}

@media (max-width: 630px) {
    .receiver-info {
        flex-direction: column;
    }
}

@media (max-width: 430px) {
    .admin-price-row > .price-col:nth-child(1) > p {
        display: none;
    }

    .admin-price,
    .price-add-form {
        margin-left: 0;
        margin-right: 0;
    }

    .admin-price-row button{
        padding: 6px;
        font-size: 14px;
    }

    .d-menu div:nth-child(3) {
        grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    }
}