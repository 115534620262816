

header {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem;
    padding-left: 0;
    width: 100%;
    height: 7rem;
    background-color: var(--Gray-0);
    box-shadow: 0 2px 10px var(--Blue-9);
    z-index: 120;
    transition: all 0.2s ease-in-out;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 0;
}

nav img {
    filter: drop-shadow(0 0 0.2px var(--Gray-7));
    width: 120px;
    margin-left: auto;
    padding: 0.5rem;
    transition: all 0.2s ease-in-out;
}

.nav-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: var(--Red-10);
    font-size: 1rem;
    width: 40%;
    transition: all 0.2s ease-in-out;
}

.nav-link {
    font-family: inherit;
    text-decoration: none;
    color: var(--Blue-10);
}

.nav-btns{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 16px;
    width: fit-content;
    text-wrap: nowrap;
    margin-right: auto;
}

.nav-login {
    padding: 6px 12px 6px 12px;
    color: var(--Green-9);
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
}

.nav-login:hover {
    color: var(--Green-0);
    background-color: var(--Green-9);
}



.nav-signup{
    padding: 6px 12px 6px 12px;
    color: var(--Gray-0);
    background-color: var(--Green-9);
    box-shadow: 0px 0px 3px var(--Blue-9);
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
}

.nav-signup:hover {
    box-shadow: 0px 0px 0px var(--Gray-7);
}

.nav-login:hover ~ .nav-signup {
    color: var(--Green-9);
    background-color: var(--Gray-0);
    box-shadow: 0px 0px 0px var(--Gray-7);
}

/* ---------------------------- burger menu----------------------------------------------- */
.nav-burger-btn {
    display: none;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    width: 45px;
    height: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.nav-burger {
    width: 30px;
    height: 3px;
    background-color: var(--Green-9);
    transition: all 0.3s ease-in-out;
}

.nav-burger::before,
.nav-burger::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: var(--Green-9);
    transition: all 0.3s ease-in-out;
}

.nav-burger::before {
    transform: translateY(-10px);
}

.nav-burger::after {
    transform: translateY(10px);
}

/* ANIMATION */

.nav-burger-btn.open .nav-burger::before {
    transform: rotate(45deg);
}

.nav-burger-btn.open .nav-burger::after {
    transform: rotate(-45deg);
}

.nav-burger-btn:hover>.nav-burger,
.nav-burger-btn:hover>.nav-burger::before,
.nav-burger-btn:hover>.nav-burger::after {
    background-color: var(--Green-9);
    cursor: pointer;
    filter: drop-shadow(0px 0px 0.3px black);
}

.nav-burger-btn.open .nav-burger {
    transform: rotate(180deg);
    background: transparent;
}

/* --------------------------------------------------------------------------- */
.burger-list {
    display: none;
    position: absolute;
    top: 6.3rem;
    left: 0px;
    margin-top: 0;
    padding-top: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    transform-origin: left;
    transition: all 0.5s ease-in-out;
    z-index: 120;
}

.burger-link {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding: 0.8rem;
    border-bottom: 1px solid var(--Blue-9);
    color: var(--Blue-9);
    background-color: var(--Gray-0);
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}

.burger-link:hover {
    text-decoration: none;
    font-size: 0.8rem;
    cursor: pointer;
    color: var(--Gray-0);
    background-color: var(--Blue-9);
}

.isActive {
    font-family: inherit;
    text-decoration: none;
    font-weight: 600;
    color: var(--Red-9);
}

@media (max-width: 1600px) {

    nav img {
        filter: drop-shadow(0 0 0.2px var(--Gray-7));
        width: 120px;
        margin-left: auto;
    }

    .nav-list {
        font-size: 1rem;
    }
}

@media (max-width: 1450px) {
    nav img {
        filter: drop-shadow(0 0 0.2px var(--Gray-7));
        width: 120px;
        margin-left: auto;
    }

    .nav-list {
        font-size: 1rem;
    }

    .nav-list .nav-link span {
        display: none;
    }

    .burger-list {
        display: flex;
    }
}

@media (max-width: 1100px) {
    .big-menu{
        display: none;
    }

    .nav-btns{
        margin-right: 0;
        margin-left: auto;
    }

    .nav-burger-btn{
        margin-right: auto;
    }

    nav img {
        filter: drop-shadow(0 0 0.2px var(--Gray-7));
        width: 120px;
        margin-left: auto;
    }

    .nav-list {
        font-size: 1rem;
    }

    .nav-list .nav-link span {
        display: none;
    }

    .nav-burger-btn {
        position: relative;
        display: flex;
    }

    .burger-list {
        display: flex;
    }
}



@media (max-width: 450px) {
    nav img {
        margin: auto;
    }

    .nav-burger-btn{
        margin: auto;
    }

    .nav-btns {
        display: none;
    }
}