*:focus {
    outline: none;
    outline-width: 0;
}

* {
    box-sizing: border-box;
    margin: 0px;
}

.home-page{
    grid-area: body;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
}

.home-page h1 {
    font-weight: 600;
    text-align: center;
    font-size: 3rem;
    transform: scaleY(2);
    color: var(--Gray-8);
    margin-top: 5vh;
    margin-bottom: 5vh;
    transition: all 0.2s ease-in-out;
}

.home-price-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 0.5rem;
    margin-bottom: 5vh;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px var(--Gray-7);
    font-size: 18px;
}

.home-price-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--Blue-11);
    color: var(--Gray-3);
    box-shadow: 0 0 5px var(--Gray-5);
}

.flag {
    width: 50px;
    height: auto;
    border-radius: 7px;
}

.home-price-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: .5rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    background-color: var(--Gray-3);
    box-shadow: 0 0 5px var(--Gray-5);
}

.home-price-col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    text-align: center;
}



/* ------------------------------------------------------------------------ */
@media (max-width: 1150px) {
    .home-page h1 {
        font-size: 2rem;
    }

    .home-price-box {
        width: 95vw;
    }
}

@media (max-width: 600px) {
    .country-name {
        display: none;
    }

    .home-page h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 500px) {
    .home-price-col {
        font-size: 0.85rem;
    }

    .flag {
        width: 40px;
    }
}

@media (max-width: 450px) {
    .home-page h1 {
        font-size: 1.2rem;
    }
}

@media (max-width: 360px) {
    .home-price-box {
        width: 99vw;
        padding: 0;
    }

    .home-price-col {
        font-size: 0.8rem;
    }
}